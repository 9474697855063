import React, { useEffect, useState } from 'react';
import Sale01 from '../components/sale/Sale01';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PageTitle from '../components/pagetitle';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { firebase } from '../Firebase/config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
UserProfile.propTypes = {

};

function UserProfile(props) {

    const [dataCoinTab] = useState([
        {
            id: 1,
            title: 'User Profile',
            icon: 'fa-user'
        },
        {
            id: 2,
            title: 'Orders',
            icon: 'fa-shopping-cart'
        },

        // {
        //     id: 3,
        //     title: 'API keys',
        //     icon: 'fa-gear'
        // },
        // {
        //     id: 4,
        //     title: '2FA',
        //     icon: 'fa-barcode'
        // },
        // {
        //     id: 5,
        //     title: 'Change password',
        //     icon: 'fa-lock'
        // },
        {
            id: 5,
            title: 'Referrals',
            icon: 'fa-share-nodes'
        },
        {
            id: 6,
            title: 'Logout',
            icon: 'fa-sign-out-alt'
        },
    ]);

    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState(null);
    const [refferalEarnings, setRefferalEarnings] = useState([]);
    const [refferallevelEarnings, setRefferallevelEarnings] = useState([]);
    const [userearn, setUserearn] = useState([]);
    const [totalWithdrawal, setTotalWithdrawal] = useState(0);
    const [Availablebalance, setAvailableBalance] = useState(0);
    const [mytotalincome, setMyTotalIncome] = useState(0);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [showPaymentDetailsModal, setShowPaymentDetailsModal] = useState(false);
    const [isEditable, setIsEditable] = useState(false); // Toggle edit mode
    const [formData, setFormData] = useState({}); // For editable form fields

    const [levels, setLevels] = useState([[], [], [], [], []]); // State for 5 levels
    const [totalIncome, setTotalIncome] = useState(0);
    const [withdrawalDetails, setWithdrawalDetails] = useState({
        name: '',
        mobileNumber: '',
        upiId: '',
        upiNumber: '',
        amount: ''
    });
    const [message, setMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const getPercentageForLevel = (level) => {
        switch (level) {
            case 1: return 0.08; // 8%
            case 2: return 0.06; // 6%
            case 3: return 0.03; // 3%
            case 4: return 0.02; // 2%
            case 5: return 0.01; // 1%
            default: return 0;
        }
    };

    useEffect(() => {
        // Ensure that user data is fetched when the component mounts or on auth state change
        const unsubscribeAuth = firebase.auth().onAuthStateChanged((currentUser) => {
            if (currentUser) {
                setLoading(true);
                // Fetch user data
                firebase.firestore().collection('users').doc(currentUser.uid).get()
                    .then((doc) => {
                        if (doc.exists) {
                            const userData = { ...doc.data(), id: doc.id };
                            setUserData(userData);
                            setFormData(userData);
    
                            // Fetch all referrals and calculate earnings
                            firebase.firestore().collection('users').get()
                                .then((querySnapshot) => {
                                    const allReferrals = [];
                                    let calculatedTotalIncome = 0;
                                    const levelData = [[], [], [], [], []];
    
                                    querySnapshot.forEach((doc) => {
                                        allReferrals.push({ ...doc.data(), id: doc.id });
                                    });
    
                                    allReferrals.forEach((referral) => {
                                        const referralIds = referral.referralId
                                            ? referral.referralId.split(',')
                                            : [];
                                        const currentIndex = referralIds.indexOf(currentUser.uid);
    
                                        if (currentIndex >= 0 && currentIndex < 5) {
                                            const payments = referral.payments
                                                ? referral.payments.map((payment) =>
                                                      parseFloat(payment)
                                                  )
                                                : [];
                                            const paymentTotal = payments.reduce(
                                                (acc, payment) => acc + payment,
                                                0
                                            );
                                            const percentage = getPercentageForLevel(
                                                currentIndex + 1
                                            );
                                            const calculatedPayment =
                                                paymentTotal * percentage;
    
                                            levelData[currentIndex].push({
                                                ...referral,
                                                calculatedPayment,
                                            });
                                            calculatedTotalIncome += calculatedPayment;
                                        }
                                    });
    
                                    setLevels(levelData);
                                    setTotalIncome(calculatedTotalIncome);
    
                                    // Update user's earnPrice after referral calculations
                                    firebase.firestore()
                                        .collection('users')
                                        .doc(currentUser.uid)
                                        .update({
                                            earnPrice:
                                                calculatedTotalIncome +
                                                userData.ourPrice,
                                        })
                                        .then(() => {
                                            console.log(
                                                'User earnPrice updated successfully.'
                                            );
                                        })
                                        .catch((error) => {
                                            console.error(
                                                'Error updating user earnPrice:',
                                                error
                                            );
                                        });
                                })
                                .catch((error) => {
                                    console.error('Error fetching referrals:', error);
                                })
                                .finally(() => {
                                    setLoading(false);
                                });
                        } else {
                            navigate('/');
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.error('Error getting document:', error);
                        setLoading(false);
                    });
            } else {
                navigate('/');
                setLoading(false);
            }
        });
    
        // Cleanup on unmount
        return () => unsubscribeAuth();
    }, [navigate]);
    
    // Calculate dynamic values based on state
    useEffect(() => {
        if (userData && totalIncome !== undefined) {
            const Availablebalance = totalIncome - totalWithdrawal;
            const mytotalincome = totalIncome + userData?.ourPrice;
    
            console.log('Available balance:', Availablebalance);
            console.log('Total withdrawal:', totalWithdrawal);
            console.log('My total income:', mytotalincome);
    
            // Optionally, you can set these to state or display in the UI
            setAvailableBalance(Availablebalance);
            setMyTotalIncome(mytotalincome);
        }
    }, [totalIncome, totalWithdrawal, userData]);
    



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    // Handle Edit Toggle
    const handleEdit = () => {
        setIsEditable(true);
    };

    // Handle Update
    const handleUpdate = () => {
        const currentUser = firebase.auth().currentUser;
        if (currentUser) {
            firebase.firestore().collection('users').doc(currentUser.uid).update(formData)
                .then(() => {
                    setUserData(formData); // Update local state
                    setIsEditable(false); // Disable edit mode
                    toast.success('Profile updated successfully!');
                })
                .catch((error) => {
                    console.error('Error updating profile:', error);
                    toast.error('Failed to update profile.');
                });
        }
    };


    const renderTable = (levelData, levelTitle) => (
        <div className="bg-white rounded-lg shadow-md p-2">
            {levelData.length > 0 ? (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-gray-50 border border-gray-200 rounded-lg">
                        <thead className="bg-indigo-600 text-white">
                            <tr>
                                <th className="py-2 px-2 text-xs font-mono">Name</th>
                                <th className="py-2 px-2 text-xs font-mono">Email</th>
                                <th className="py-2 px-2 text-xs font-mono">Phone</th>
                                <th className="py-2 px-2 text-xs font-mono">Payments</th>
                                <th className="py-2 px-2 text-xs font-mono">Level Income</th>
                            </tr>
                        </thead>
                        <tbody>
                            {levelData.map(referral => (
                                <tr key={referral.id} className="hover:bg-gray-100 border-b">
                                    <td className="py-2 px-2 text-xs font-mono">{referral.firstName} {referral.lastName}</td>
                                    <td className="py-2 px-2 text-xs font-mono">{referral.email}</td>
                                    <td className="py-2 px-2 text-xs font-mono">{referral.phoneNumber}</td>
                                    <td className="py-2 px-2 text-xs font-mono">{referral.payments ? referral.payments.join(', ') : 'No payments'}</td>
                                    <td className="py-2 px-2 text-xs font-mono">₹{referral.calculatedPayment.toFixed(2)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <p className="text-gray-500">No referrals found at {levelTitle}</p>
            )}
        </div>
    );

    const earnPrice = typeof userData?.earnPrice === 'number' ? userData?.earnPrice : 0;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setWithdrawalDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));

        // Validation logic
        if (name === 'amount') {
            const withdrawalAmount = parseFloat(value);
            if (withdrawalAmount > Availablebalance) {
                setMessage(`Amount cannot be greater than earned price (${Availablebalance}).`);
            } else {
                setMessage('');
            }
        }
    };



    const handleWithdrawalSubmit = async (e) => {
        e.preventDefault();
        const { amount } = withdrawalDetails;
        const withdrawalAmount = parseFloat(amount);
    
        // Check if the amount is valid
        if (withdrawalAmount <= 0) {
            toast.error('Please enter a valid amount.');
            return;
        }
    
        if (Availablebalance < 500) {
            toast.error('Total Token must be at least 500 to request a withdrawal.');
            return;
        }
    
        if (withdrawalAmount > Availablebalance) {
            toast.error(`Amount cannot be greater than Total Token (${Availablebalance}).`);
            return;
        }
    
        setIsSubmitting(true);
    
        try {
            const currentUser = firebase.auth().currentUser;
            if (currentUser) {
                await firebase.firestore().collection('withdrawals').add({
                    ...withdrawalDetails,
                    userId: currentUser.uid,
                    status: 'Checking',
                    availableAmount: Availablebalance,
                    submittedAt: new Date().toISOString(), // Add the current date and time
                });
                toast.success('Withdrawal submitted successfully.');
                setShowModal(false); // Close the modal after submission
                setWithdrawalDetails({
                    name: '',
                    mobileNumber: '',
                    upiId: '',
                    upiNumber: '',
                    amount: '',
                });
                window.location.reload();
            }
        } catch (error) {
            toast.error('Error submitting withdrawal: ' + error.message);
        } finally {
            setIsSubmitting(false);
        }
    };
    








    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
        const textField = document.createElement('textarea');
        textField.innerText = `https://medherbsmarketing.com/login?refferal=${userData?.id}`;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        setCopied(true);

        // Reset the "Copied" state after a brief delay
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };


    const shareReferral = async () => {
        try {
            await navigator.share({
                title: 'Share Referral Link',
                text: `Join me on Medherbs and earn rewards!`,
                url: `https://medherbsmarketing.com/login/?refferal=${userData?.id}`
            });
        } catch (error) {
            console.error('Sharing failed', error);
        }
    };



    const handleLogout = () => {
        firebase.auth().signOut().then(() => {
            // Sign out successful, navigate to home page
            navigate('/'); // Redirect to the home page
        }).catch((error) => {
            // Handle errors if any during logout
            console.log('Logout error:', error);
        });
    };

    const [orders, setOrders] = useState([]);

    useEffect(() => {
        const currentUser = firebase.auth().currentUser;
        const fetchOrders = async () => {
            try {
                const db = firebase.firestore();
                // Corrected the query to use '==' for comparison
                const ordersRef = db.collection('orders').where('email', '==', currentUser.email);
                const snapshot = await ordersRef.get();
                const ordersData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setOrders(ordersData);
            } catch (error) {
                console.error('Error fetching orders:', error);
            }
        };

        if (currentUser) {
            fetchOrders();
        }
    }, []);

    const handleViewClick = (payment) => {
        setSelectedPayment(payment);
        setShowPaymentDetailsModal(true);
    };

    const handleCloseModal = () => {
        setShowPaymentDetailsModal(false);
        setSelectedPayment(null);
    }

    const [payments, setPayments] = useState([]);

    useEffect(() => {
        const unsubscribeAuth = firebase.auth().onAuthStateChanged((currentUser) => {
            if (currentUser) {
                // Set loading state before fetching data
                setLoading(true);
    
                const fetchOrders = async () => {
                    try {
                        const db = firebase.firestore();
                        const ordersRef = db.collection('withdrawals').where('userId', '==', currentUser.uid);
        
                        // Real-time listener for the 'withdrawals' collection
                        const unsubscribe = ordersRef.onSnapshot((snapshot) => {
                            const ordersData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                            setPayments(ordersData);
        
                            // Filter payments with status "Paid"
                            const paidPayments = ordersData.filter(payment => payment.status === 'Paid' || payment.status === 'Checking');
        
                            // Calculate total withdrawal amount
                            const total = paidPayments.reduce((sum, payment) => sum + parseFloat(payment.amount), 0);
                            setTotalWithdrawal(total);
        
                            console.log("payments", ordersData);
                            console.log("totalWithdrawal", total);
        
                            setLoading(false); // Set loading to false after the fetch is complete
                        });
        
                        // Return the unsubscribe function to clean up the listener when the component unmounts
                        return unsubscribe;
                    } catch (error) {
                        console.error('Error fetching orders:', error);
                        setLoading(false); // Set loading to false in case of an error
                    }
                };
    
                fetchOrders();
            } else {
                setLoading(false); // Set loading to false if the user is not authenticated
            }
        });
    
        // Cleanup on unmount
        return () => {
            unsubscribeAuth(); // Unsubscribe from the auth listener
            setLoading(false); // Ensure loading state is set to false when component unmounts
        };
    }, []); // Empty dependency array to only run once when the component mounts
    

    return (

        <div>


{loading ? (
                <div className="flex justify-center items-center h-screen">
                <div className="rounded-full h-20 w-20 bg-green-800 animate-ping"></div>
            </div> // Show loading indicator when loading is true
            ) : (
       
                <>
                    <PageTitle heading='User Profile' title='User' /><section className="user-profile flat-tabs">

                        <div className="container">
                            <div className="row">
                                <Tabs>

                                    <TabList>
                                        <div className="user-info center">
                                            {/* <div className="avt">
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                id="imgInp"
                                                required />
                                            <img id="blah" src={userData.photo} alt="no file" />
                                        </div> */}
                                            <h6 className="name">{userData?.firstName} {userData?.middleName} {userData?.lastName} </h6>
                                            <p style={{ fontWeight: 'bold', fontSize: 12 }} >{userData?.email}</p>
                                        </div>
                                        {dataCoinTab.map(idx => (
                                            <Tab key={idx.id} onClick={idx.title === 'Logout' ? handleLogout : null}><h6 className="fs-16">
                                                <i className={`fa ${idx.icon}`}></i>
                                                {idx.title}
                                            </h6></Tab>
                                        ))}

                                    </TabList>

                                    <TabPanel>
                                        <div className="content-inner profile">
                                            <form action="#">
                                                <h4>User Profile</h4>
                                                {/* {userData.status === 'Active' ? (
                <h6 className='text-green-500 text-sm'> Your Account Verified</h6>
            ) : (
                <h6 className='text-yellow-500 text-sm'>Your Account  Verification is Under Process</h6>
            )} */}
                                                <div className="form-group d-flex s1">
                                                    <input
                                                        type="text"
                                                        name="firstName"
                                                        className="form-control"
                                                        value={formData.firstName || ''}
                                                        onChange={handleChange}
                                                        readOnly={!isEditable}
                                                    />

                                                    <input
                                                        type="text"
                                                        name="middleName"
                                                        className="form-control"
                                                        value={formData.middleName || ''}
                                                        onChange={handleChange}
                                                        readOnly={!isEditable}
                                                    />

                                                    <input
                                                        type="text"
                                                        name="lastName"
                                                        className="form-control"
                                                        value={formData.lastName || ''}
                                                        onChange={handleChange}
                                                        readOnly={!isEditable}
                                                    />
                                                </div>

                                                <div className="form-group d-flex">
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        className="form-control"
                                                        value={formData.email || ''}
                                                        onChange={handleChange}
                                                        readOnly />
                                                    <div className="sl">
                                                        <select
                                                            className="form-control"
                                                            id="exampleFormControlSelect1"
                                                        >
                                                            <option>+91</option>
                                                        </select>
                                                        <input
                                                            type="text"
                                                            name="phoneNumber"
                                                            className="form-control"
                                                            value={formData.phoneNumber || ''}
                                                            onChange={handleChange}
                                                            readOnly={!isEditable}
                                                            placeholder="Your Phone number" />
                                                    </div>
                                                </div>
                                                <div className="form-group d-flex">
                                                    <div style={{ flexDirection: 'column' }} className="sl">
                                                        <label style={{ marginLeft: 8 }} htmlFor="upino">UPI No.</label>
                                                        <input
                                                            type="text"
                                                            name="upino"
                                                            className="form-control"
                                                            value={formData.upino || ''}
                                                            onChange={handleChange}
                                                            readOnly={!isEditable} />
                                                    </div>
                                                    <div style={{ flexDirection: 'column' }} className="sl">
                                                        <label htmlFor="upiid">UPI ID.</label>
                                                        <input
                                                            type="text"
                                                            name="upiid"
                                                            className="form-control"
                                                            value={formData.upiid || ''}
                                                            onChange={handleChange}
                                                            readOnly={!isEditable}
                                                            placeholder="Your Phone number" />
                                                    </div>
                                                </div>

                                                <div className="form-group d-flex">
                                                    <input
                                                        type="text"
                                                        name="fulladdress"
                                                        className="form-control"
                                                        value={formData.fulladdress || ''}
                                                        onChange={handleChange}
                                                        readOnly={!isEditable}
                                                    />

                                                    <input
                                                        type="text"
                                                        name="city"
                                                        className="form-control"
                                                        value={formData.city || ''}
                                                        onChange={handleChange}
                                                        readOnly={!isEditable} />
                                                    <div className="sl">

                                                        <input
                                                            type="text"
                                                            name="pincode"
                                                            className="form-control"
                                                            value={formData.pincode || ''}
                                                            onChange={handleChange}
                                                            readOnly={!isEditable} />
                                                    </div>



                                                </div>
                                                <div className="form-group d-flex">
                                            <div style={{flexDirection:'column'}} className="sl">
                                            <label style={{marginLeft:8}}  htmlFor="aadharcardnumber">Aadhar No.</label>
                                                <input
                                                         type="text"
                                                         name="aadharcardnumber"
                                                         className="form-control"
                                                         value={formData.aadharcardnumber || ''}
                                                         onChange={handleChange}
                                                         readOnly={!isEditable} />
                                                    </div>
                                                <div style={{flexDirection:'column'}} className="sl">
                                                <label htmlFor="nomineeAadharcardnumber">Nominee Aadhar No.</label>
                                                    <input
                                                             type="text"
                                                             name="nomineeAadharcardnumber"
                                                             className="form-control"
                                                             value={formData.nomineeAadharcardnumber || ''}
                                                             onChange={handleChange}
                                                             readOnly={!isEditable} />
                                                </div>
                                            </div>



                                                {!isEditable ? (
                                                    <button type="button" className="btn btn-primary" onClick={handleEdit}>
                                                        Edit
                                                    </button>
                                                ) : (
                                                    <button type="button" className="btn btn-success" onClick={handleUpdate}>
                                                        Update
                                                    </button>
                                                )}
                                            </form>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>

                                        <div className="content-inner referrals">
                                            <h6>Total Orders</h6>

                                            <div class="overflow-x-auto pb-8">
                                                <table class="min-w-full bg-white font-[sans-serif]">
                                                    <thead class="bg-gray-100 whitespace-nowrap">
                                                        <tr>
                                                            <th class="px-6 py-1 text-left text-sm font-semibold text-black">
                                                                Product
                                                            </th>
                                                            <th class="px-6 py-1 text-left text-sm font-semibold text-black">
                                                                Price
                                                            </th>
                                                            <th class="px-6 py-1 text-left text-sm font-semibold text-black">
                                                                Transaction I'd
                                                            </th>
                                                            <th class="px-6 py-1 text-left text-sm font-semibold text-black">
                                                                Order Status
                                                            </th>
                                                            <th class="px-6 py-1 text-left text-sm font-semibold text-black">
                                                                Order Date
                                                            </th>
                                                            <th class="px-6 py-1 text-left text-sm font-semibold text-black">
                                                                Address
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody class="whitespace-nowrap divide-y divide-gray-200">
                                                        {orders.map(order => (
                                                            <tr key={order.id} >

                                                                <td class="px-6 py-3 text-sm">
                                                                    <div class="flex items-center cursor-pointer">
                                                                        <img src={order.productimage} class="w-10 h-10 p-1.5 shrink-0 bg-gray-100" />
                                                                        <div class="ml-4">
                                                                            <p class="text-sm text-black">{order.productname}</p>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="px-6 py-3 text-sm">
                                                                    ₹{order.productprice}
                                                                </td>
                                                                <td class="px-6 py-3 text-sm">
                                                                    {order.transactioid}
                                                                </td>
                                                                <td className="px-6 py-4 text-sm">
                                                                    <span className={`w-[68px] block text-center py-0.5 font-semibold rounded text-xs ${order.orderConfirmation === 'Pending' ? ' text-yellow-500' :
                                                                            order.orderConfirmation === 'Placed' ? ' text-yellow-800' :
                                                                                order.orderConfirmation === 'Delivered' ? ' text-green-500' :
                                                                                    order.orderConfirmation === 'Rejected' ? ' text-red-500' :
                                                                                        ''
                                                                        }`}>
                                                                        {order.orderConfirmation}
                                                                    </span>
                                                                </td>

                                                                <td class="px-6 py-3">
                                                                    {order.orderDate.toDate().toLocaleString()}
                                                                </td>
                                                                <td class="px-6 py-3">
                                                                    {order.address}-{order.city}-{order.state},{order.zipCode}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>

                                            </div>

                                        </div>
                                    </TabPanel>
                                    <TabPanel>

                                        <div className="content-inner referrals">

                                            <div className="main">
                                                <h6>Invite friends to earn </h6>

                                                <div className="refe">
                                                    <div>
                                                        <p>Referral link</p>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            readOnly
                                                            value={`https://medherbsmarketing.com/login/?refferal=${userData?.id}`} />
                                                        <span className="btn-action" onClick={copyToClipboard}>
                                                            {copied ? 'Copied' : 'Copy'}
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>

                                            <span style={{ marginBottom: 8 }} className="btn-action" onClick={shareReferral}>
                                                Share Referral
                                            </span>

                                            <div className="bg-white rounded-lg  p-6 mb-6">
                                            <div className="grid mb-4 gap-6 md:grid-cols-3 sm:grid-cols-1">
  <div className="p-4 bg-white rounded-2xl shadow-lg hover:shadow-xl transition-shadow">
    <div className="flex items-center space-x-4">
      <div className="text-indigo-600 text-4xl">
        <i className="fas fa-wallet"></i> {/* Replace with an icon library of your choice */}
      </div>
      <div>
        <h3 className="text-xl font-bold text-gray-700">Available Balance</h3>
        <p className="text-2xl font-mono font-bold text-indigo-600">
          ₹{Availablebalance.toFixed(2)}
        </p>
      </div>
    </div>
  </div>
  <div className="p-4 bg-white rounded-2xl shadow-lg hover:shadow-xl transition-shadow">
    <div className="flex items-center space-x-4">
      <div className="text-green-600 text-4xl">
        <i className="fas fa-chart-line"></i>
      </div>
      <div>
        <h3 className="text-xl font-bold text-gray-700">Total Earn</h3>
        <p className="text-2xl font-mono font-bold text-green-600">
          ₹{mytotalincome.toFixed(2)}
        </p>
      </div>
    </div>
  </div>
  <div className="p-4 bg-white rounded-2xl shadow-lg hover:shadow-xl transition-shadow">
    <div className="flex items-center space-x-4">
      <div className="text-red-600 text-4xl">
        <i className="fas fa-money-bill-wave"></i>
      </div>
      <div>
        <h3 className="text-xl font-bold text-gray-700">Withdrawal Balance</h3>
        <p className="text-2xl font-mono font-bold text-red-600">
          ₹{totalWithdrawal.toFixed(2)}
        </p>
      </div>
    </div>
  </div>
</div>

                                                <button
                                                    onClick={() => setShowModal(true)}
                                                    className="bg-green-600 font-mono text-white px-4 py-2 rounded-lg mb-4"
                                                >
                                                    Withdrawal Payments
                                                </button>

                                                {showModal && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
    <div className="bg-white p-6 rounded-2xl shadow-2xl w-full max-w-lg transform transition-transform duration-300 scale-100">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-2xl font-semibold font-mono text-gray-800">Withdrawal Form</h3>
        <button
          type="button"
          onClick={() => setShowModal(false)}
          className="text-red-500 hover:text-red-600 transition duration-200 text-xl"
        >
          &times;
        </button>
      </div>
      <form onSubmit={handleWithdrawalSubmit} className="space-y-6">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={withdrawalDetails.name}
            onChange={handleInputChange}
            className="mt-2 w-full border border-gray-300 rounded-lg shadow-sm px-3 py-2 focus:ring-2 focus:ring-indigo-500 focus:outline-none"
            required
          />
        </div>
        <div>
          <label htmlFor="mobileNumber" className="block text-sm font-medium text-gray-700">Mobile Number</label>
          <input
            type="text"
            id="mobileNumber"
            name="mobileNumber"
            value={withdrawalDetails.mobileNumber}
            onChange={handleInputChange}
            className="mt-2 w-full border border-gray-300 rounded-lg shadow-sm px-3 py-2 focus:ring-2 focus:ring-indigo-500 focus:outline-none"
            required
          />
        </div>
        <div>
          <label htmlFor="upiId" className="block text-sm font-medium text-gray-700">UPI ID</label>
          <input
            type="text"
            id="upiId"
            name="upiId"
            value={withdrawalDetails.upiId}
            onChange={handleInputChange}
            className="mt-2 w-full border border-gray-300 rounded-lg shadow-sm px-3 py-2 focus:ring-2 focus:ring-indigo-500 focus:outline-none"
            required
          />
        </div>
        <div>
          <label htmlFor="upiNumber" className="block text-sm font-medium text-gray-700">UPI Number</label>
          <input
            type="text"
            id="upiNumber"
            name="upiNumber"
            value={withdrawalDetails.upiNumber}
            onChange={handleInputChange}
            className="mt-2 w-full border border-gray-300 rounded-lg shadow-sm px-3 py-2 focus:ring-2 focus:ring-indigo-500 focus:outline-none"
            required
          />
        </div>
        <div>
          <label htmlFor="amount" className="block text-sm font-medium text-gray-700">Amount</label>
          <input
            type="number"
            id="amount"
            name="amount"
            value={withdrawalDetails.amount}
            onChange={handleInputChange}
            className="mt-2 w-full border border-gray-300 rounded-lg shadow-sm px-3 py-2 focus:ring-2 focus:ring-indigo-500 focus:outline-none"
            placeholder="Enter amount"
          />
          {message && <p className="text-red-500 text-xs mt-2">{message}</p>}
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className={`w-full bg-indigo-600 text-white py-2 rounded-lg shadow-md transition duration-300 ${
              isSubmitting ? 'opacity-70 cursor-wait' : 'hover:bg-indigo-700'
            }`}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </div>
      </form>
    </div>
  </div>
)}


                                                <Tabs>
                                                    <TabList>
                                                        <Tab>Level 1</Tab>
                                                        <Tab>Level 2</Tab>
                                                        <Tab>Level 3</Tab>
                                                        <Tab>Level 4</Tab>
                                                        <Tab>Level 5</Tab>
                                                    </TabList>

                                                    {levels.map((levelData, index) => (
                                                        <TabPanel key={index}>
                                                            {renderTable(levelData, `Level ${index + 1}`)}
                                                        </TabPanel>
                                                    ))}
                                                </Tabs>
                                            </div>
                                            <div className="overflow-x-auto">
                                                <h1 className='text-xl font-bold font-mono text-black py-4 underline' >Transaction History</h1>
                                                <table className="min-w-full bg-white border border-gray-200">
                                                    <thead className="bg-gray-100 border-b">
                                                        <tr>
                                                            <th className="py-2 px-4 border-b font-mono">Name</th>
                                                            <th className="py-2 px-4 border-b font-mono">Amount</th>
                                                            <th className="py-2 px-4 border-b font-mono">Date</th>
                                                            <th className="py-2 px-4 border-b font-mono">Mobile Number</th>
                                                            <th className="py-2 px-4 border-b font-mono">UPI ID</th>
                                                            <th className="py-2 px-4 border-b font-mono">Status</th>
                                                            <th className="py-2 px-4 border-b font-mono"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
    {payments.length > 0 ? (
        [...payments] // Create a copy of the payments array
            .sort((a, b) => new Date(b.submittedAt) - new Date(a.submittedAt)) // Sort by date descending
            .map((payment, index) => (
                <tr key={index} className="border-b hover:bg-gray-50">
                    <td className="py-2 px-4 border-b font-mono">{payment.name}</td>
                    <td className="py-2 px-4 border-b font-mono">{payment.amount}</td>
                    <td className="py-2 px-4 border-b font-mono">
                        {payment.submittedAt
                            ? new Date(payment.submittedAt).toLocaleString() // Format the date and time
                            : "-"}
                    </td>
                    <td className="py-2 px-4 border-b font-mono">{payment.mobileNumber}</td>
                    <td className="py-2 px-4 border-b font-mono">{payment.upiId}</td>
                    <td
                        className={`py-2 px-4 border-b font-mono ${
                            payment.status === "Checking"
                                ? "bg-yellow-500 text-white"
                                : payment.status === "Paid"
                                ? "bg-green-500 text-white"
                                : ""
                        }`}
                    >
                        {payment.status}
                    </td>
                    {payment.status === "Paid" && (
                        <td className="py-2 px-4 border-b">
                            <a
                                href={payment.paymentScreenshotURL}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="bg-blue-500 font-mono text-white px-4 py-2 rounded-lg"
                            >
                                View Payment
                            </a>
                        </td>
                    )}
                </tr>
            ))
    ) : (
        <tr>
            <td colSpan="7" className="py-2 px-4 text-center">
                No payments found.
            </td>
        </tr>
    )}
</tbody>


                                                </table>
                                            </div>
                                            {showPaymentDetailsModal && selectedPayment && (
                                                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-30 relative">
                                                    <div className="bg-white p-8 rounded-lg shadow-lg  w-full relative">
                                                        <h3 className="text-xl font-semibold mb-4">Payment Details</h3>
                                                        <div className="space-y-4">
                                                            <p><strong>Name:</strong> {selectedPayment.name}</p>
                                                            <p><strong>Amount:</strong> {selectedPayment.amount}</p>
                                                            <p><strong>Mobile Number:</strong> {selectedPayment.mobileNumber}</p>
                                                            <p><strong>UPI ID:</strong> {selectedPayment.upiId}</p>
                                                            <p><strong>Status:</strong> {selectedPayment.status}</p>
                                                        </div>
                                                        <button
                                                            onClick={handleCloseModal}
                                                            className="mt-4 bg-gray-300 text-gray-700 px-4 py-2 rounded-lg"
                                                        >
                                                            Close
                                                        </button>
                                                    </div>
                                                </div>
                                            )}



                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="content-inner api">
                                            <h6>Enable API access on your account to generate keys.</h6>
                                            <h4>API Access is <span>Disabled</span></h4>
                                            <p className="mail">
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M20 5H4C3.44772 5 3 5.44772 3 6V18C3 18.5523 3.44772 19 4 19H20C20.5523 19 21 18.5523 21 18V6C21 5.44772 20.5523 5 20 5ZM4 3C2.34315 3 1 4.34315 1 6V18C1 19.6569 2.34315 21 4 21H20C21.6569 21 23 19.6569 23 18V6C23 4.34315 21.6569 3 20 3H4Z"
                                                        fill="#23262F" />
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M5.2318 7.35984C5.58537 6.93556 6.21593 6.87824 6.64021 7.2318L11.3598 11.1648C11.7307 11.4739 12.2694 11.4739 12.6402 11.1648L17.3598 7.2318C17.7841 6.87824 18.4147 6.93556 18.7682 7.35984C19.1218 7.78412 19.0645 8.41468 18.6402 8.76825L13.9206 12.7013C12.808 13.6284 11.192 13.6284 10.0795 12.7013L5.35984 8.76825C4.93556 8.41468 4.87824 7.78412 5.2318 7.35984Z"
                                                        fill="#23262F" />
                                                </svg>
                                                petersonkenn@demo.com
                                            </p>
                                            <div className="main">
                                                <h6>Enable API keys</h6>
                                                <p>Enter your password and 2FA code to Enable the API keys</p>

                                                <div className="refe">
                                                    <div className="form-group">
                                                        <p>Your Password</p>
                                                        <input
                                                            className="form-control"
                                                            type="password"
                                                            placeholder="Passworld" />
                                                    </div>
                                                    <div className="form-group">
                                                        <p>2FA Code</p>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="2FA code" />
                                                    </div>
                                                </div>
                                                <Link to="#" className="btn-action">Enable API keys</Link>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="content-inner api">
                                            <h4>2FA <span className="color-success">Enabled</span></h4>
                                            <p>
                                                If you want to turn off 2FA, input your account password and
                                                the six-digit code provided by the Google Authenticator app
                                                below, then click <strong>"Disable 2FA"</strong>.
                                            </p>

                                            <div className="main">
                                                <h6>Disable 2FA</h6>
                                                <p>
                                                    Enter your password and 2FA code to Disable the 2FA
                                                    verification
                                                </p>

                                                <div className="refe">
                                                    <div className="form-group">
                                                        <p>Your Password</p>
                                                        <input
                                                            className="form-control"
                                                            type="password"
                                                            placeholder="Passworld" />
                                                    </div>
                                                    <div className="form-group">
                                                        <p>2FA Code</p>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="2FA code" />
                                                    </div>
                                                </div>
                                                <Link to="#" className="btn-action">Disable 2FA verification</Link>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="content-inner profile change-pass">
                                            <h4>Change Password</h4>
                                            <h6>New Passworld</h6>
                                            <form action="#">
                                                <div className="form-group">
                                                    <div>
                                                        <label>Old Passworld<span>*</span>:</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value="123456789" />
                                                    </div>
                                                    <div>
                                                        <label>2FA Code<span>*</span>:</label>
                                                        <input type="text" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div>
                                                        <label>New Passworld<span>*</span>:</label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            placeholder="New Passworld" />
                                                    </div>
                                                    <div>
                                                        <label>Confirm Passworld<span>*</span>:</label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            placeholder="Confirm Passworld" />
                                                    </div>
                                                </div>
                                            </form>
                                            <button type="submit" className="btn-action">
                                                Change Passworld
                                            </button>
                                        </div>
                                    </TabPanel>


                                </Tabs>
                            </div>
                        </div>
                    </section>
                    {/* <Sale01 /> */}
                </>
            )}
            <ToastContainer />

        </div>
    );
}






export default UserProfile;