import React from "react";
import { Link, NavLink } from 'react-router-dom';
const BestSeller8 = ({ productData }) => {
  const renderStars = (averageRating) => {
    const stars = [];
    const roundedRating = Math.round(averageRating);
    for (let i = 0; i < 5; i++) {
      if (i < roundedRating) {
        stars.push(<i key={i} className="fas fa-star text-yellow-400" />);
      } else {
        stars.push(<i key={i} className="far fa-star text-yellow-400" />);
      }
    }
    return stars;
  };

  // Slice the productData array to only include the first 10 items
  const firstTenItems = productData && productData.filter(item => item.Trending === "Yes").slice(0, 10);


  return (
    <div className="pb-2 mt-20 px-8 rounded-lg">
      <div >
        <div className="2xl:container 2xl:mx-auto flex flex-col justify-center items-center sm:pb-12 lg:pb-0 space-y-4 px-4 md:px-6 2xl:px-0">
          <div>
            <p className="text-2xl font-mono uppercase lg:text-4xl font-semibold leading-9 text-center text-[#98d900]">
              Our Products
            </p>
          </div>

        </div>
      </div>

      <div className="px-4 flex justify-center py-4">
        <div className="grid grid-cols-1 gap-4 sm:gap-6 lg:gap-8 sm:grid-cols-2 lg:grid-cols-4 w-full max-w-7xl">
          {firstTenItems.map((item, index) => (
            <div key={index} className="w-full max-w-sm mx-auto lg:max-w-none">
              <div className="p-4 bg-white rounded shadow dark:bg-gray-700 transition duration-300 transform hover:-translate-y-1 hover:shadow-xl">
                <Link
                  to={`/Product-Details?id=${item.id}`}
                  className="block overflow-hidden"
                >
                   <img
                    className="w-full h-64 object-cover rounded-lg"
                    src={item.images[0]} // Use the first image URL here
                    alt={item.title}
                  />
                </Link>
                {/* <p className="text-sm font-semibold text-gray-600 mt-1">
                  {renderStars(item.averageRating)} {item.averageRating.toFixed(1)} / 5 <div className="flex">

                  </div>
                </p> */}
                <Link to={`/Product-Details?id=${item.id}`} className="overflow-hidden block h-12">
                <h3 className="text-lg font-mono uppercase font-bold text-[#98d900] dark:text-white mb-1 truncate overflow-hidden">
                  {item.title}
                </h3>
              </Link>
                <p className="mb-1 text-xl font-mono font-bold text-[#98d900] dark:text-[#98d900] ">
                  <span>₹{item.offerprice}</span>
                  <span className="text-sm font-semibold text-gray-400 ml-2 line-through ">₹{item.price}</span>
                </p>

                <div className="flex justify-center bg-[#98d900] space-x-4 p-2 rounded-lg">
                  {/* {item.Stock === 'In stock' ? (
                    <button
                      
                      className="flex  font-sans uppercase items-center justify-center font-bold text-sm text-center text-white dark:text-white group"
                    >
                      <h1 className="text-xl ">🛒 </h1> Add to Cart
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="ml-2 transition-all group-hover:translate-x-2"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                        />
                      </svg>
                    </button>
                  ) : (
                    <button disabled className="flex  font-sans uppercase items-center justify-center font-bold text-sm text-center text-white cursor-not-allowed dark:text-white group">
                      <h1 className="text-xl text-white"> </h1> Item Out of Stock
                    </button>
                  )} */}
                  <Link
                    to={`/Product-Details?id=${item.id}`}
                    className="flex  font-sans uppercase items-center justify-center font-bold text-center text-white dark:text-white text-sm group"
                  >
                    <h1 className="text-xl">👁️‍🗨️</h1>View Product
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="ml-2 transition-all group-hover:translate-x-2"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                      />
                    </svg>
                  </Link>
                </div>

              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-center py-4">
        <button  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4">
        <Link to='/Product'> View More</Link> 
        </button>
       
      </div>

    </div>
  );
};

export default BestSeller8;