import React, { useState,useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { firebase } from '../Firebase/config';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
function Login(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0); // 0 for Login tab, 1 for Signup tab
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();


  const [referralId, setReferralId] = useState(false);
const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const referralId = searchParams.get('refferal');
    setReferralId(referralId); // Print the referral ID to console (you can do anything you want with it)
  }, [location.search]);

  const [id, setId] = useState('');
  const [mainReferralId, setMainReferralId] = useState(null);
  const [username, setUserName] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const referralId = urlParams.get("refferal");
    setMainReferralId(referralId); // Store the referral ID

    const fetchData = async () => {
        setLoading(true);
        try {
            const query = firebase.firestore().collection("users").where("id", "==", referralId);
            const snapshot = await query.get();
            const users = snapshot.docs.map(doc => {
                const data = doc.data();
                data.id = doc.id;
                return data;
            });
            if (users.length > 0) {
                setUserName(users[0].firstName);
                setId(users[0].referralId);
                console.log("usesr",users)
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            setLoading(false);
        }
    };

    fetchData();
}, []);
console.log("refferlaids",mainReferralId)
console.log("username",username)
const handleLogin = async () => {
  try {
    setLoading(true);

    // Use email and password states for form values
    const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);
    const user = userCredential.user;

    setLoading(false);
    toast.success('Login successful!');
    console.log('User logged in successfully:', user);

    // Redirect to the home page after successful login
    navigate('/');
  } catch (error) {
    setLoading(false);

    let errorMessage;

    // Handle specific Firebase Authentication error codes
    switch (error.code) {
      case 'auth/invalid-email':
        errorMessage = 'Invalid email address.';
        break;
      case 'auth/wrong-password':
        errorMessage = 'Incorrect password.';
        break;
      case 'auth/user-not-found':
        errorMessage = 'No user found with this email.';
        break;
      default:
        errorMessage = `Invalid email address and password`;
        break;
    }

    toast.error(errorMessage);
    console.error('Error during login:', error.message);
  }
};



const handleRegister = async () => {
  const fields = [
    { id: 'firstName', label: 'First Name' },
    { id: 'middleName', label: 'Middle Name' },
    { id: 'lastName', label: 'Last Name' },
    { id: 'phonenumber', label: 'Phone Number' },
    { id: 'upino', label: 'UPI Number' },
    { id: 'upiid', label: 'UPI ID' },
    { id: 'fulladdress', label: 'Full Address' },
    { id: 'city', label: 'City' },
    { id: 'pincode', label: 'Pincode' },
    { id: 'nomineeName', label: 'Nominee Name' },
    { id: 'aadharcardnumber', label: 'Aadhar Card Number' },
    { id: 'nomineeAadharcardnumber', label: 'Nominee Aadhar Card Number' },
  ];

  let hasError = false;

  fields.forEach((field) => {
    const value = document.getElementById(field.id)?.value.trim();
    if (!value) {
      hasError = true;
      toast.error(`${field.label} is required.`);
    }
  });

  if (!email) {
    hasError = true;
    toast.error('Email is required.');
  }

  if (!password) {
    hasError = true;
    toast.error('Password is required.');
  }

  if (hasError) return;

  try {
    setLoading(true);

    const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);
    const user = userCredential.user;
    const refferaid = mainReferralId && id ? `${mainReferralId},${id}` : mainReferralId || id;

    await firebase.firestore().collection('users').doc(user.uid).set({
      firstName: document.getElementById('firstName').value.trim(),
      middleName: document.getElementById('middleName').value.trim(),
      lastName: document.getElementById('lastName').value.trim(),
      referralId: refferaid,
      phoneNumber: document.getElementById('phonenumber').value.trim(),
      email,
      upino: document.getElementById('upino').value.trim(),
      upiid: document.getElementById('upiid').value.trim(),
      fulladdress: document.getElementById('fulladdress').value.trim(),
      city: document.getElementById('city').value.trim(),
      pincode: document.getElementById('pincode').value.trim(),
      nomineeName: document.getElementById('nomineeName').value.trim(),
      aadharcardnumber: document.getElementById('aadharcardnumber').value.trim(),
      nomineeAadharcardnumber: document.getElementById('nomineeAadharcardnumber').value.trim(),
      id: user.uid,
      registrationDate: firebase.firestore.FieldValue.serverTimestamp(),
    });

    setLoading(false);
    toast.success('Registration successful!');
    setSelectedIndex(0);
  } catch (error) {
    setLoading(false);
    toast.error(`Error during registration: ${error.message}`);
    console.error('Error during registration:', error.message);
  }
};




    return (
        <div>
             
             {/* <PageTitle heading='Login' title='Login' /> */}


            <section className="register login">
            <div className="container">
                <div className="row">
               
                <div className="col-md-12">
                <Tabs selectedIndex={selectedIndex} onSelect={(index) => setSelectedIndex(index)}>
                    
                    <TabList>
      
                        <Tab><h6 className="fs-16">Login</h6></Tab>
                        <Tab><h6 className="fs-16">Signup</h6></Tab>

                    </TabList>



                    <TabPanel>
                    <div className="col-md-12">
                    <div className="block-text center">
                    <h3 className="heading">Login To Medherbs</h3>
                    <p className="desc fs-20">
                    Welcome to Medherbs! Log in to access personalized wellness solutions, natural products, expert advice, and a community dedicated to your health and wealth. Let’s take the next step towards a vibrant you!
                    </p>
                   
                    </div>
                </div>
                        <div className="content-inner">
                        <form onSubmit={(e) => { e.preventDefault(); handleLogin(); }}>
                                <div className="form-group">
                                <label for="exampleInputEmail1">Email/ID</label>
                                <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                placeholder="Please fill in the email form."
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
                                </div>
                                <div className="form-group s1">
                                <label>Password </label>
                                <input
                type="password"
                className="form-control"
                placeholder="Please enter a password."
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

                                </div>

                                <div className="form-group form-check">
                                <div>
                                    <input type="checkbox" className="form-check-input" />
                                    <label className="form-check-label">Remember Me</label>
                                </div>
                                <a href='/forgotpassword' >
                                <p>Forgot Password?</p>
                                </a>
                                </div>

                                <button
                type="button"
                style={{
                  backgroundColor: '#4caf50',
                  color: '#fff',
                  padding: '10px 15px',
                  fontSize: '16px',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease',
                }}
                onClick={handleLogin}
              >
                {loading ? 'Loading...' : 'Login'}
              </button>
                               
                            </form>
                        </div>
                    </TabPanel>

                    <TabPanel>
                    <div className="col-md-12">
                    <div className="block-text center">
                    <h3 className="heading">Signup To Medherbs</h3>
                    <p className="desc fs-20">
                    Welcome to Medherbs! Register to access personalized wellness solutions, natural products, expert advice, and a community dedicated to your health and wealth. Let’s take the next step towards a vibrant you!
                    </p>
               
                    </div>
                </div>
                <div className="content-inner">
                            <form>
                            <div className='flex mt-8 flex-col md:flex-row'>
    <div className="form-group" style={{ marginRight: '10px' }}>
        <label htmlFor="firstName">First Name</label>
        <input
            type="text"
            className="form-control"
            id="firstName"
            placeholder="Enter Your First Name."
        />
    </div>
    <div className="form-group" style={{ marginRight: '10px' }}>
        <label htmlFor="middleName">Middle Name</label>
        <input
            type="text"
            className="form-control"
            id="middleName"
            placeholder="Enter Your Middle Name."
        />
    </div>
    <div className="form-group">
        <label htmlFor="lastName">Last Name</label>
        <input
            type="text"
            className="form-control"
            id="lastName"
            placeholder="Enter Your Last Name."
        />
    </div>
</div>
<div className="form-group">
                                <label for="phonenumber">Mobile Phone</label>
                                <div>
  <input
    type="tel"
    id="phonenumber"
    className="form-control"
    placeholder="Your Phone number"
    maxLength={10}
    onInput={(e) => (e.target.value = e.target.value.replace(/[^0-9]/g, ''))}
  />
</div>

                                </div>

                                <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Email/ID</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    placeholder="Please fill in the email form."
                                    value={email}  // Add this line to bind the value to the email state
                                    onChange={(e) => setEmail(e.target.value)}  // Add this line to update the email state
                                />
                            </div>
                                <div className="form-group">
        <label htmlFor="upiid">UPI ID</label>
        <input
            type="text"
            className="form-control"
            id="upiid"
            placeholder="Enter Your UPI ID."
        />
    </div>
    <div className="form-group">
        <label htmlFor="upino">UPI No.</label>
        <input
            type="text"
            className="form-control"
            id="upino"
            placeholder="Enter Your UPI No."
        />
    </div>
    
    <div className="form-group">
        <label htmlFor="fulladdress">Full Address</label>
        <input
            type="text"
            className="form-control"
            id="fulladdress"
            placeholder="Enter Your Full Address."
        />
    </div>
    <div className='flex flex-col md:flex-row'>
    <div className="form-group" style={{ marginRight: '10px' }}>
        <label htmlFor="city">City</label>
        <input
            type="text"
            className="form-control"
            id="city"
            placeholder="Enter Your City."
        />
    </div>
  
    <div className="form-group">
        <label htmlFor="pincode">Pin Code</label>
        <input
            type="tel"
            className="form-control"
            id="pincode"
            placeholder="Enter Your Pin Code."
            maxLength={6}
            onInput={(e) => (e.target.value = e.target.value.replace(/[^0-9]/g, ''))}
        />
    </div>
</div>
<div className="form-group">
        <label htmlFor="refferedid">Reffered By</label>
        <input
            type="text"
            className="form-control"
            id="refferedid"
            value={referralId}
            placeholder="Enter Reffered Id."
        />
    </div>
    <div className="form-group">
  <label htmlFor="aadharcardnumber">Enter Your Aadhar Card Number</label>
  <input
            type="tel"
            className="form-control"
            id="aadharcardnumber"
            placeholder="Enter Your Aadhar Card Number"
            maxLength={12}
            onInput={(e) => (e.target.value = e.target.value.replace(/[^0-9]/g, ''))}
        />
</div>

    <div className="form-group">
  <label htmlFor="nomineeName">Nominee Name</label>
  <input
    type="text"
    className="form-control"
    id="nomineeName"
    placeholder="Enter Nominee Name"
  />
</div>


<div className="form-group">
  <label htmlFor="nomineeAadharcardnumber">Enter Nominee Aadhar Card Number</label>
  <input
            type="tel"
            className="form-control"
            id="nomineeAadharcardnumber"
            placeholder="Enter Nominee Aadhar Card Number"
            maxLength={12}
            onInput={(e) => (e.target.value = e.target.value.replace(/[^0-9]/g, ''))}
        />
</div>

                                <div className="form-group s1">
                                <label>Password </label>
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Please enter a password."
                                    value={password}  // Add this line to bind the value to the password state
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                </div>

                                <div className="form-group form-check">
                           
                                </div>

                                <button
                type="button"
                style={{
                  backgroundColor: '#4caf50',
                  color: '#fff',
                  padding: '10px 15px',
                  fontSize: '16px',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease',
                }}
                onClick={handleRegister}
              >
            {loading ? `Uploading... ` : 'Register'}
              </button>

                            </form>
                        </div>
                    </TabPanel>

                    

                </Tabs> 

                    {/* <div className="qr-code center">
                    <img src={img} alt="" />
                    <h6 className="fs-20">Login with QR code</h6>
                    <p className="fs-14">
                        Scan this code with the <span>Medherbs mobile app</span> <br />
                        to log in instantly.
                    </p>
                    </div> */}
                </div>
                </div>
            </div>
            </section>
{/* 
            <Sale01 /> */}
            <ToastContainer/>
        </div>
    );
}

export default Login;