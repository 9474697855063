import HomeOne from "./HomeOne";
import HomeTwo from "./HomeTwo";
import HomeThree from "./HomeThree";
import BuyCrypto from "./BuyCrypto";
import BuyCryptoConfirm from "./BuyCryptoConfirm";
import BuyCryptoDetails from "./BuyCryptoDetails";
import Markets from "./Markets";
import SellSelect from "./SellSelect";
import SellCryptoAmount from "./SellCryptoAmount";
import SellCryptoConfirm from "./SellCryptoConfirm";
import SellCryptoDetails from "./SellCryptoDetails";
import Blog from "./Blog";
import BlogGrid01 from "./BlogGrid01";
import BlogGrid02 from "./BlogGrid02";
import BlogList from "./BlogList";
import BlogDetails from "./BlogDetails";
import Wallet from "./Wallet";
import UserProfile from "./UserProfile";
import Login from "./Login";
import Register from "./Register";
import Contact from "./Contact";
import Faq from "./Faq";
import Signinsinup from "./signinsinup";
import Askuserinfo from "./askuserinfo";
import CompletedDeals from "./Completed-Deals";
import OngoingDeals from "./Ongoing-Deals";
import Portfolio from "./Portfolio";
import Payment from "./Payment";
import Test from "./Test";
import ProductDetails from "./Product-Details";
import Product from "./Product";
import Gallery from "./Gallery";
import News from "./News";
import Carrer from "./Carrer";
import ShopByCategory from "./ShopByCategory";
import ShopByProduct from "./ShopByProduct";
import OrderDetails from "./OrderDetails";
import ForgotPassword from "./forgotpassword";





const routes = [
  { path: '/', component: <HomeOne />},
  { path: '/home-v2', component: <HomeTwo />},
  { path: '/home-v3', component: <HomeThree />},
  { path: '/buy-crypto-select', component: <BuyCrypto />},
  { path: '/buy-crypto-confirm', component: <BuyCryptoConfirm />},
  { path: '/buy-crypto-details', component: <BuyCryptoDetails />},
  { path: '/markets', component: <Markets />},
  { path: '/sell-select', component: <SellSelect />},
  { path: '/sell-crypto-amount', component: <SellCryptoAmount />},
  { path: '/sell-crypto-confirm', component: <SellCryptoConfirm />},
  { path: '/sell-crypto-details', component: <SellCryptoDetails />},
  { path: '/blog-default', component: <Blog />},
  { path: '/blog-grid-v1', component: <BlogGrid01 />},
  { path: '/blog-grid-v2', component: <BlogGrid02 />},
  { path: '/blog-list', component: <BlogList />},
  { path: '/blog-details', component: <BlogDetails />},
  { path: '/wallet', component: <Wallet />},
  { path: '/user-profile', component: <UserProfile />},
  { path: '/signinsinup', component: <Signinsinup />},
  { path: '/askuserinfo', component: <Askuserinfo />},
  { path: '/login', component: <Login />},
  { path: '/register', component: <Register />},
  { path: '/contact', component: <Contact />},
  { path: '/faq', component: <Faq />},
  { path: '/completed-deals', component: <CompletedDeals />},
  { path: '/ongoing-deals', component: <OngoingDeals />},
  { path: '/Portfolio', component: <Portfolio />},
  { path: '/Payment', component: <Payment />},
  { path: '/test', component: <Test />},
  { path: '/Product-Details', component: <ProductDetails />},
  { path: '/Product', component: <Product />},
  { path: '/Gallery', component: <Gallery />},
  { path: '/News', component: <News />},
  { path: '/Carrer', component: <Carrer />},
  { path: '/Shop BY CATEGORY', component: <ShopByCategory />},
  { path: '/Shop BY Product', component: <ShopByProduct />},
  { path: '/OrderDetails', component: <OrderDetails />},
  { path: '/forgotpassword', component: <ForgotPassword />},


]

export default routes;